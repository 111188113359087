import * as React from "react"
import Heading from "../components/heading/Heading"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

const PolicyPage = () => (
  <Layout>
    <Seo title="Polityka prywatności" />
    <section className="blankPage container container--small">
      <div className="content">
        <Heading type="1" text='Polityka prywatności' size="XL" />

        {/*  */}

        <p><strong>POLITYKA PRYWATNOŚCI</strong></p>
        <p><strong>Centrum Medyczne ENEL-MED Spółka Akcyjna</strong>, z siedzibą w Warszawie, ul. Słomińskiego 19 lok. 524, 00-195 Warszawa, wypełniając obowiązki wynikające z obowiązujących przepisów prawa dotyczących ochrony danych osobowych, w tym Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako RODO), pragnie przekazać Państwu informacje dotyczące przetwarzania i&nbsp;ochrony Państwa danych osobowych.</p>
        <p>Informacje zawarte w <em>Polityce prywatności</em> pomogą Państwu zrozumieć, jakie dane osobowe zbiera i przetwarza Centrum Medyczne ENEL-MED S.A., w jakim celu są one wykorzystywane oraz jakie przysługują Państwu prawa w&nbsp;związku z ochroną danych osobowych.</p>
        <p><strong>Zapraszamy do zapoznania się z poniższymi informacjami. </strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>KTO ZBIERA I PRZETWARZA PAŃSTWA DANE OSOBOWE, CZYLI KTO JEST ADMINISTRATOREM PAŃSTWA DANYCH OSOBOWYCH?</strong></p>
        <p>Centrum Medyczne ENEL-MED Spółka Akcyjna, z siedzibą w Warszawie, ul. Słomińskiego 19 lok. 524, 00-195 Warszawa (dalej jako <strong>CM ENEL-MED </strong>lub <strong>ADO</strong> – administrator danych osobowych).</p>
        <p><strong>JAK MOGĄ PAŃSTWO SKONTAKTOWAĆ SIĘ Z CM ENEL-MED? </strong></p>
        <p>Mogą Państwo skontaktować się z CM ENEL-MED:</p>
        <ol>
          <li>za pomocą e-maila: <a href="mailto:enel@enel.pl">enel@enel.pl</a>,</li>
          <li> za pomocą telefonu: (+48)&nbsp;22&nbsp;431&nbsp;77&nbsp;01/02,</li>
          <li>drogą pocztową: ul. Słomińskiego 19 lok. 524, 00-195 Warszawa,</li>
          <li> za pomocą formularza kontaktowego dostępnego na stronie <a href="http://www.enel.pl">enel.pl</a>.</li>
        </ol>
        <p>W CM ENEL-MED jest powołany Inspektor Ochrony Danych Osobowych, z którym mogą Państwo skontaktować się:</p>
        <ol>
          <li>za pomocą e‑maila: <a href="mailto:iod@enel.pl">iod@enel.pl</a>,</li>
          <li>drogą pocztową: ul. Słomińskiego 19 lok. 524, 00-195 Warszawa,</li>
          <li>za pomocą formularza kontaktowego dostępnego na stronie <a href="http://www.enel.pl">enel.pl</a>.</li>
        </ol>
        <p>&nbsp;</p>
        <p><strong>KIEDY ORAZ JAKIE DANE OSOBOWE ZBIERA I PRZETWARZA CM ENEL-MED? </strong></p>
        <p>CM ENEL-MED zbiera i przetwarza dane osobowe w związku z:</p>
        <ol>
          <li>świadczeniem usług medycznych;</li>
          <li>świadczeniem usług drogą elektroniczną, w tym usługi dostępu do prowadzonego przez CM ENEL-MED systemu on-line;</li>
          <li>sprzedażą produktów i usług za pośrednictwem prowadzonego przez CM ENEL-MED sklepu internetowego, sprzedażą produktów i usług w oddziałach CM ENEL-MED, w tym realizacją zamówienia i kontaktem z klientem;</li>
          <li>podejmowanymi działaniami marketingowymi;</li>
          <li>nawiązywaniem kontaktu za pośrednictwem dostępnych na stronach internetowych formularzy;</li>
          <li>nawiązanymi relacjami handlowymi.</li>
        </ol>
        <p>&nbsp;</p>
        <p><strong>JAKIE DANE OSOBOWE ZBIERA I PRZETWARZA CM ENEL-MED? </strong></p>
        <p>CM ENEL-MED zbiera i przetwarza dane osobowe w zakresie niezbędnym do zrealizowania celu, w którym zostały zebrane.</p>
        <p>W zależności od celu oraz podstawy prawnej zebrania i przetwarzania danych osobowych CM ENEL-MED może zbierać i przetwarzać m.in. następujące dane:</p>
        <ol>
          <li>dane identyfikujące, w tym między innymi: imię, nazwisko, PESEL, data urodzenia;</li>
          <li>dane kontaktowe, w tym między innymi: adres, numer telefonu, adres e-mail;</li>
          <li>dane zbierane i przetwarzane do postawienia diagnozy i przeprowadzenia procesu leczenia, w tym w&nbsp;szczególności dane dotyczące stanu zdrowia, z zastrzeżeniem, że dotyczy to wyłącznie osób korzystających ze świadczonych przez CM ENEL-MED usług medycznych.</li>
        </ol>
        <p><strong>W JAKIM CELU ORAZ NA JAKIEJ PODSTAWIE PRAWNEJ CM ENEL-MED PRZETWARZA PAŃSTWA DANE OSOBOWE ORAZ JAK DŁUGO SĄ PRZECHOWYWANE? </strong></p>
        <ol>
          <li><strong>Jeśli korzystają Państwo z usług medycznych świadczonych przez CM ENEL-MED:</strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter">
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzana danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Udzielanie świadczeń zdrowotnych, w tym prowadzenie dokumentacji medycznej; realizacja umowy na świadczenie usług medycznych</td>
                <td width="40%">art. 6 ust. 1 lit b lub c RODO oraz art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta oraz Rozporządzenia Ministra Zdrowia w sprawie rodzajów, zakresu i wzorów dokumentacji medycznej oraz sposobu jej przetwarzania (dalej jako Rozporządzenie Ministra Zdrowia)</td>
                <td rowspan="8" width="151">Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie opieki zdrowotnej oraz zarządzanie systemami i usługami opieki zdrowotnej, np. identyfikacji tożsamości podczas rejestracji oraz przed udzielaniem świadczeń zdrowotnych<p></p>
                  <p>&nbsp;</p></td>
                <td width="40%">art. 6 ust. 1 lit. c oraz art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 i 26 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta, §10 ust. 1 pkt 2 Rozporządzenia Ministra Zdrowia, art. 32 w zw. z art. 3 ust. 1 ustawy o systemie informacji w ochronie zdrowia</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie zabezpieczenia społecznego oraz zarządzania systemami i usługami zabezpieczenia społecznego, np. wystawianie zaświadczeń lub zwolnień lekarskich</td>
                <td width="40%">art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta oraz art. 54 ustawy o świadczeniach pieniężnych z ubezpieczenia społecznego w razie choroby i macierzyństwa</td>
              </tr>
              <tr>
                <td width="40%">Profilaktyka zdrowotna</td>
                <td width="40%">art. 9 ust. 2 lit. h RODO w związku z art. 3 ust. 2 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta<p></p>
                  <p>art. 6 ust. 1 lit. e RODO</p>
                  <p>art. 9 ust. 2 lit. g RODO</p>
                  <p>art. 9 ust. 2 lit. i RODO</p></td>
              </tr>
              <tr>
                <td width="40%">Medycyny pracy, w tym oceny zdolności do pracy pracownika</td>
                <td width="40%">art. 9 ust. 2 lit h RODO w związku z art. 6, 11 i 12 ustawy o służbie medycyny pracy</td>
              </tr>
              <tr>
                <td width="40%">Postawienie diagnozy medycznej</td>
                <td width="40%">art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta</td>
              </tr>
              <tr>
                <td width="40%">Realizacja praw pacjenta, w tym prawa do wyznaczenia osoby upoważnionej do udostępnienia jej dokumentacji medycznej lub/i informowania jej o stanie zdrowia oraz udostępniania dokumentacji medycznej lub/i informacji osobie upoważnionej</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 9 ust. h RODO oraz art. 26 ust. 1 ustawy o prawach pacjenta oraz § 8 ust. 1 Rozporządzenia Ministra Zdrowia</td>
              </tr>
              <tr>
                <td width="40%">Kontakt poprzez wykorzystanie numeru telefonu lub adresu e-mail w celu np. potwierdzenia wizyty, odwołania terminu wizyty lub zabiegu, poinformowania o możliwości odbioru wyniku badań, w celu podejmowania działań w ramach profilaktyki zdrowotnej, co stanowi również prawnie uzasadniony interes ADO, jakim jest obsługa pacjenta</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO<p></p>
                  <p>art. 6 ust. 1 lit. e RODO</p>
                  <p>art. 6 ust. 1 lit. f RODO</p></td>
              </tr>
              <tr>
                <td width="40%">Objęcie programem medycznym w ramach badań medycyny pracy, na podstawie umowy o świadczenie usług medycznych zawartej przez pracodawcę i/lub zleceniodawcę z ADO, w tym rejestracji i weryfikacji uprawnień do skorzystania z usług medycznych świadczonych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO<p></p>
                  <p>art. 6 ust. 1 lit. c RODO w związku z art. 6, art. 11 ust. 1 i art. 12 ust. 2 pkt 1 ustawy o służbie medycyny pracy</p>
                  <p>art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta</p></td>
                <td rowspan="2" width="151">Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa<p></p>
                  <p>Okres obowiązywania umowy</p></td>
              </tr>
              <tr>
                <td width="40%">Objęcie programem medycznym w zakresie udzielania świadczeń zdrowotnych na podstawie umowy zawartej przez pracodawcę i/lub zleceniodawcę z ADO, w tym między innymi rejestracji i weryfikacji uprawnień do skorzystania z usług medycznych świadczonych przez ADO, kontaktu poprzez wykorzystanie numeru telefonu lub adresu e-mail w sprawach związanych z objęciem opieką medyczną w ramach programu, co stanowi również prawnie uzasadniony interes ADO</td>
                <td width="40%">art. 6 ust. 1 lit. a lub b RODO<p></p>
                  <p>art. 6 ust. 1 lit. f – prawnie uzasadniony interes ADO, którym jest realizacja umowy zawartej przez ADO z pracodawcą lub zleceniodawcą</p>
                  <p>art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta</p></td>
              </tr>
              <tr>
                <td width="40%">Realizacja umowy o świadczenie usługi drogą elektroniczną w postaci prowadzenia konta w systemie on-line – dotyczy osób posiadających konto w prowadzonym przez ADO systemie on-line</td>
                <td width="40%">art. 6 ust. 1 lit. a i b RODO</td>
                <td width="20%">Okres prowadzenia konta</td>
              </tr>
              <tr>
                <td width="40%">Ustalenie, dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi również prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO<p></p>
                  <p>art. 9 ust. 2 lit. f RODO</p></td>
                <td width="20%">Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
                <td width="20%">Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i&nbsp;mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM ENEL-MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">1 miesiąc</td>
              </tr>
              <tr>
                <td width="40%">Wykorzystanie i rozpowszechnianie wizerunku w celach marketingowych na podstawie udzielonej przez Państwa zgody</td>
                <td width="40%">art. 6 ust. 1 lit. a RODO – zgoda na przetwarzanie i wykorzystanie wizerunku</td>
                <td width="20%">Przez okres niezbędny do osiągnięcia celu jednak nie dłużej niż do czasu wycofania przez Państwa zgody</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="2">
          <li><strong>Jeśli korzystają Państwo z innych usług (niż usługi medyczne) świadczonych przez CM ENEL-MED (np. usługi kosmetyczne, usługi trenera personalnego): </strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Realizacja umowy o świadczenie usługi lub podjęcie żądanych działań przed zawarciem umowy</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO</td>
                <td width="20%">Okres niezbędny do zrealizowania umowy (zrealizowanie usługi)</td>
              </tr>
              <tr>
                <td width="40%">Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
                <td width="20%">Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM&nbsp;ENEL‑MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">1 miesiąc</td>
              </tr>
              <tr>
                <td width="40%">Wykorzystanie i rozpowszechnianie wizerunku w celach marketingowych na podstawie udzielonej przez Państwa zgody</td>
                <td width="40%">Art. 6 ust. 1 lit. a RODO – zgoda na przetwarzanie i wykorzystanie wizerunku</td>
                <td width="20%">Przez okres niezbędny do osiągnięcia celu jednak nie dłużej niż do czasu wycofania przez Państwa zgody</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="3">
          <li><strong>Jeśli są Państwo klientami CM ENEL-MED, w tym klientami prowadzonego przez CM ENEL-MED sklepu internetowego: </strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Realizacja umowy sprzedaży, w tym dostarczenie towaru lub usługi, kontakt w&nbsp;związku z realizacją zamówienia</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO</td>
                <td width="20%">Okres niezbędny do zrealizowania umowy sprzedaży (od złożenia zamówienia do jego zrealizowania)</td>
              </tr>
              <tr>
                <td width="40%">Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
                <td width="20%">Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM&nbsp;ENEL-MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">1 miesiąc</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="4">
          <li><strong>Jeśli są Państwo kontrahentami CM ENEL-MED:</strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter">
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Realizacja zawartej z CM ENEL-MED umowy</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO</td>
                <td width="20%">Okres obowiązywania umowy</td>
              </tr>
              <tr>
                <td width="40%">Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
                <td width="20%">Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM ENEL-MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO<p></p>
                  <p>&nbsp;</p></td>
                <td width="20%">1 miesiąc</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="5">
          <li><strong> Jeśli działają Państwo w charakterze przedstawiciela kontrahenta CM ENEL-MED, w tym jako osoba wyznaczona do kontaktu, osoba upoważniona do dokonywania określonych działań w imieniu kontrahenta:</strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Realizacja umowy zawartej przez CM ENEL-MED z podmiotem, w którego imieniu Państwo występują, co stanowi uzasadniony interes prawny ADO</td>
                <td width="40%">art. 6 ust. 1 lit. &nbsp;f RODO</td>
                <td width="20%">Okres obowiązywania umowy, przez który działają Państwo jako przedstawiciel kontrahenta/ klienta CM ENEL-MED</td>
              </tr>
              <tr>
                <td width="40%">Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO</td>
                <td width="20%">Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej, jeśli Państwa dane były podane w umowie lub innych dokumentach związanych z realizacją przez ADO obowiązków wynikających z przepisów prawa, w szczególności przepisach podatkowych i o rachunkowości</td>
                <td width="40%">art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
                <td width="20%">Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM ENEL-MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO<p></p>
                  <p>&nbsp;</p></td>
                <td width="20%">1 miesiąc</td>
              </tr>
              <tr>
                <td width="40%">Dostęp do <em>Elektronicznego portalu klienta Enel-Care</em>, co stanowi prawnie uzasadniony interes ADO</td>
                <td width="40%">art. 6 ust. 1 lit. b, f RODO</td>
                <td width="20%">Okres obowiązywania umowy, przez który działają Państwo jako przedstawiciel klienta CM ENEL-MED</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="6">
          <li><strong> Jeśli są Państwo potencjalnymi kontrahentami CM ENEL-MED: </strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="20%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Podejmowanie działań przed zawarciem umowy na Państwa żądanie</td>
                <td width="40%">art. 6 ust. 1 lit. b RODO</td>
                <td width="20%">do momentu podjęcia decyzji w przedmiocie zawarcia umowy</td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie CM ENEL-MED, oddziałach i szpitalach CM ENEL-MED, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO<p></p>
                  <p>&nbsp;</p></td>
                <td width="20%">1 miesiąc</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="7">
          <li><strong> Jeśli korzystają Państwo z formularzy kontaktowych dostępnych na stronach internetowych prowadzonych przez ADO: </strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="20%"><strong>&nbsp;</strong></td>
                <td width="20%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="40%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Kontakt w związku z pytaniem/ zgłoszeniem złożonym za pośrednictwem formularza kontaktowego, na podstawie udzielonej zgody na przetwarzanie danych<p></p>
                  <p>&nbsp;</p></td>
                <td width="20%"></td>
                <td width="20%">art. 6 ust. 1 lit. a RODO</td>
                <td width="40%">Przez okres niezbędny do odpowiedzi na zgłoszenie i prowadzenia korespondencji/ rozmów związanych ze zgłoszeniem, jednak nie dłużej niż do wycofania przez Panią/Pana zgody na przetwarzanie danych osobowych</td>
              </tr>
              <tr>
                <td width="40%">Dochodzenia roszczeń i obrony przed roszczeniami związanymi z prowadzoną przez ADO działalnością gospodarczą, co stanowi uzasadniony interes prawny ADO, gdy Państwa zgłoszenie może uzasadniać przetwarzanie danych w tym celu (np. gdy zgłoszenie dotyczy reklamacji)</td>
                <td width="20%"></td>
                <td width="20%">art. 6 ust. 1 lit f RODO<p></p>
                  <p>art. 9 ust. 2 lit. f RODO</p></td>
                <td width="40%">Przez okres przedawnienia roszczeń określony w przepisach prawa</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <ol start="8">
          <li><strong> Jeśli kontaktują się Państwo z ADO za pośrednictwem infolinii (inicjując lub odbierając połączenie telefoniczne) </strong></li>
        </ol>
        <p><strong>&nbsp;</strong></p>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="40%"><strong>Cel przetwarzania danych</strong></td>
                <td width="20%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="40%"><strong>Okres przechowywania/ przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="40%">Zapewnienie jakości i bezpieczeństwa świadczonych usług na podstawie wyrażonej przez Państwa zgody na nagranie rozmowy, co stanowi również prawnie uzasadniony interes ADO<p></p>
                  <p>&nbsp;</p></td>
                <td width="20%">art. 6 ust. 1 lit. a RODO<p></p>
                  <p>art. 6 ust. 1 lit. f RODO</p></td>
                <td width="40%">6 miesięcy</td>
              </tr>
              <tr>
                <td width="40%">Realizacja zamówionego kontaktu i prowadzenie związanej z tym kontaktem korespondencji</td>
                <td width="20%">art. 6 ust. 1 lit. a RODO</td>
                <td width="40%">Przez okres niezbędny do zrealizowania zamówionego kontaktu i prowadzenia korespondencji z nim związanej, jednak nie dłużej niż do wycofania przez Panią/Pana zgody na przetwarzanie danych osobowych</td>
              </tr>
              <tr>
                <td width="40%">Świadczenie usług medycznych i zarządzanie tymi usługami</td>
                <td width="20%">art. 9 ust. 2 lit. h RODO</td>
                <td width="40%">6 miesięcy</td>
              </tr>
              <tr>
                <td width="40%">Ustalenie, dochodzenie roszczeń i obrona przed roszczeniami związanymi z prowadzoną przez ADO działalnością gospodarczą, co stanowi również prawnie uzasadniony interes prawny ADO</td>
                <td width="20%">art. 6 ust. 1 lit f RODO<p></p>
                  <p>art. 9 ust. 2 lit. f RODO</p></td>
                <td width="40%">6 miesięcy</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <ol start="9">
          <li><strong> Jeśli podejmowane są wobec Państwa przez CM ENEL-MED działania marketingowe /wysyłany jest newsletter: </strong></li>
        </ol>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="248"><strong>Cel przetwarzania danych</strong></td>
                <td width="40%"><strong>Podstawa prawna przetwarzania danych</strong></td>
                <td width="40%"><strong>Okres przechowywania /przetwarzania danych osobowych</strong></td>
              </tr>
              <tr>
                <td width="248">Prowadzenie działań marketingowych związanych z działalnością CM ENEL-MED, w tym nawiązywanie relacji handlowych, wysyłanie ofert, informacji o towarach, usługach, promocjach, newsletterów – w ramach prawnie uzasadnionego interesu ADO lub wyrażonej przez Państwa zgody</td>
                <td width="40%">art. 6 ust. 1 lit. f RODO, co stanowi prawnie uzasadniony interes ADO, którym jest marketing produktów i usług<p></p>
                  <p>art. 6 ust. 1 lit. a RODO, art. 10 ust. 2 ustawy o świadczeniu usług drogą elektroniczną, art. 172 Prawa telekomunikacyjnego</p></td>
                <td width="40%">Przez okres niezbędny do osiągnięcia celu, jednak nie dłużej niż do:<p></p>
                  <p>1) złożenia przez Państwa sprzeciwu wobec przetwarzania danych osobowych w tym celu lub</p>
                  <p>2) jeśli wyrażali Państwo zgody marketingowe – do wycofania udzielonej zgody</p></td>
              </tr>
              <tr>
                <td width="248">Profilowanie (tworzenie profili preferencji) zmierzające do dostosowania oferowanych przez ADO usług do Państwa potrzeb, co stanowi uzasadniony interes prawny ADO; Do profilowania ADO nie wykorzystuje żadnych danych szczególnej kategorii, w tym danych znajdujących się w prowadzonej przez ADO dokumentacji medycznej.</td>
                <td width="40%">Art. 6 ust. 1 lit. f RODO</td>
                <td width="40%">Przez okres niezbędny do osiągnięcia celu, jednak nie dłużej niż do złożenia przez Państwa sprzeciwu wobec przetwarzania danych osobowych w tym celu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>SKĄD CM ENEL-MED POZYSKUJE PAŃSTWA DANE? </strong></p>
        <p><strong>&nbsp;</strong></p>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="30%"><strong>Pacjenci:</strong><p></p>
                  <p>Korzystanie z usług medycznych świadczonych przez CM ENEL-MED</p></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa lub osoby upoważnionej podczas rezerwacji wizyty oraz wykonywania na Państwa rzecz usługi medycznej, m.in. konsultacji lekarskiej, badania, zabiegu.<p></p>
                  <p>&nbsp;</p>
                  <p>W przypadku korzystania przez Państwa z programu medycznego w zakresie udzielania świadczeń zdrowotnych na podstawie umowy (np.&nbsp;ENEL-CARE i medi-care), w pierwszej kolejności dane pozyskiwane są od Państwa na podstawie wypełnionego przez Państwa formularza przystąpienia do programu lub zostają przekazane ADO przez pracodawcę/zleceniodawcę.</p>
                  <p>&nbsp;</p>
                  <p>W przypadku korzystania przez Państwa z programu medycznego w ramach badań medycyny pracy dane: imię, nazwisko, PESEL,</p>
                  <p>(w przypadku osoby, której nie nadano numeru PESEL – seria, numer i nazwa dokumentu stwierdzającego tożsamość), data urodzenia (w przypadku osoby przyjmowanej do pracy), adres zamieszkania (miejscowość, ulica, nr domu, nr lokalu) oraz stanowisko i opis warunków pracy, zostają przekazane ADO przez Państwa pracodawcę.</p>
                  <p>&nbsp;</p>
                  <p>ADO może pozyskać również Państwa dane od innych podmiotów leczniczych w ramach udostępniania dokumentacji medycznej, jeśli jest to niezbędne do zapewnienia ciągłości świadczeń zdrowotnych.</p></td>
              </tr>
              <tr>
                <td width="30%"><strong>Klienci: </strong><p></p>
                  <p>Korzystanie z innych niż medyczne usług świadczonych przez CM ENEL-MED, np. usług kosmetycznych, usług trenera personalnego</p></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa przed zawarciem umowy lub w trakcie jej realizacji.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Klienci: </strong><p></p>
                  <p>Dokonywanie zakupów, w tym korzystanie ze sklepu internetowego</p></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa lub od &nbsp;osoby zawierającej umowę, przed zawarciem umowy lub w trakcie jej realizacji.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Kontrahenci: </strong><p></p>
                  <p>Nawiązywanie relacji handlowych</p></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa przed zawarciem umowy lub w trakcie jej realizacji.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Przedstawiciele kontrahenta, osoby do kontaktu</strong></td>
                <td width="70%">Dane pozyskiwane są od podmiotu, w imieniu którego Państwo występują lub bezpośrednio od Państwa. W przypadku gdy dane osobowe podawane są przez podmiot, w imieniu którego Państwo występują, ADO zbiera Państwa dane: imię, nazwisko, numer telefonu, adres e-mail, stanowisko służbowe.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Potencjalni kontrahenci </strong></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa lub ze źródeł publicznie dostępnych. W przypadku gdy dane osobowe zbierane są ze źródeł publicznie dostępnych, ADO zbiera Państwa dane osobowe: imię, nazwisko, nazwa firmy, numer telefonu, adres e-mail, stanowisko służbowe,</td>
              </tr>
              <tr>
                <td width="30%"><strong>Użytkownicy formularzy kontaktowych</strong></td>
                <td width="70%">Dane pozyskiwane są bezpośrednio od Państwa w formularzu kontaktowym lub podczas kontaktu prowadzonego w celu odpowiedzi na zgłoszenie dokonane za pośrednictwem formularza kontaktowego.</td>
              </tr>
              <tr>
                <td width="30%"><strong>&nbsp;</strong><p></p>
                  <p><strong>Osoby kontaktujące się z ADO&nbsp; za pośrednictwem infolinii </strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>&nbsp;</strong></p></td>
                <td width="70%">Dane osobowe utrwalane podczas rozmowy telefonicznej lub przekazane w celu realizacji zamówionego kontaktu są pozyskiwane&nbsp; bezpośrednio od Państwa. Część utrwalanych podczas rozmowy danych ADO może już posiadać, co może być wynikiem łączącego Państwa z ADO stosunku prawnego/łączącej relacji biznesowej. W takiej sytuacji ADO podczas rozmowy telefonicznej może posługiwać się już wcześniej zebranymi danymi osobowymi.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Adresaci działań marketingowych</strong></td>
                <td width="70%">Dane mogą być pozyskiwane:<p></p>
                  <p>1) bezpośrednio od Państwa;</p>
                  <p>2) od podmiotu, w imieniu którego Państwo występują (imię, nazwisko, numer telefonu, adres e-mail, stanowisko służbowe);</p>
                  <p>3) ze źródeł publicznie dostępnych (imię, nazwisko, nazwa firmy, numer telefonu, adres e-mail, stanowisko służbowe)</p></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>&nbsp;</strong></p>
        <p><strong>CZY PODANIE PRZEZ PAŃSTWA SWOICH DANYCH JEST KONIECZNE? </strong></p>
        <p><strong>&nbsp;</strong></p>
        <div className="table-responsive">
          <table className="table aligncenter" >
            <tbody>
              <tr>
                <td width="30%"><strong>Pacjenci:</strong><p></p>
                  <p>Korzystanie z usług medycznych świadczonych przez CM ENEL-MED</p></td>
                <td width="70%">Podanie przez Państwa danych osobowych jest dobrowolne, jednak stanowi warunek ustawowy w zakresie prowadzenia przez ADO dokumentacji, w tym dokumentacji medycznej w sposób określony przepisami prawa, w tym do identyfikacji Państwa tożsamości. W związku z powyższym niepodanie danych może skutkować odmową rezerwacji wizyty, rejestracji albo odmową udzielenia świadczenia zdrowotnego – odmowa podania danych może uniemożliwić udzielanie świadczeń zdrowotnych przez ADO. W przypadku niepodania numeru telefonu i adresu e-mail ADO nie może odmówić udzielenia Państwu świadczenia zdrowotnego, jednak podanie tych danych ułatwia korzystanie ze świadczeń dzięki możliwości kontaktowania się w sprawie realizacji usług, w tym potwierdzenia i odwołania wizyty.<p></p>
                  <p>Jeśli korzystają Państwo z prowadzonego przez ADO systemu on-line, podanie przez Państwa danych niezbędnych do prowadzenia konta jest warunkiem zawarcia umowy o świadczenie usługi prowadzenia konta. Odmowa podania danych skutkować będzie brakiem możliwości założenia i prowadzenia konta w systemie on-line.</p></td>
              </tr>
              <tr>
                <td width="30%"><strong>Klienci: </strong><p></p>
                  <p>Korzystanie z innych niż medyczne usług świadczonych przez CM ENEL-MED, np. usług kosmetycznych, usług trenera personalnego</p></td>
                <td width="70%">Podanie przez Państwa danych niezbędnych do realizacji umowy jest warunkiem zawarcia umowy. Odmowa ich podania skutkować będzie brakiem możliwości skorzystania z usługi lub wystawienia faktury.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Klienci: </strong><p></p>
                  <p>Dokonywanie zakupów, w tym korzystanie ze sklepu internetowego</p></td>
                <td width="70%">Podanie przez Państwa danych niezbędnych do realizacji umowy sprzedaży jest warunkiem zawarcia umowy sprzedaży. Odmowa ich podania będzie skutkować brakiem możliwości dokonania zakupu lub wystawienia faktury.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Kontrahenci:</strong><p></p>
                  <p>Nawiązywanie relacji handlowych</p></td>
                <td width="70%">Podanie przez Państwa danych niezbędnych do złożenia oferty, a następnie podpisania i realizacji umowy, jest warunkiem zawarcia umowy. Odmowa ich podania skutkować będzie brakiem możliwości złożenia oferty oraz zawarcia umowy.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Przedstawiciele kontrahenta, osoby do kontaktu </strong></td>
                <td width="70%">Jeżeli podają Państwo dane osobowe bezpośrednio ADO, ich podanie jest dobrowolne, ale potrzebne do realizacji przez ADO umowy na rzecz podmiotu, w imieniu którego Państwo występują. W przypadku niepodania danych realizacja umowy może być utrudniona.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Potencjalni kontrahenci</strong></td>
                <td width="70%">Jeśli podają Państwo dane bezpośrednio ADO, ich podanie jest dobrowolne, ale niezbędne do podjęcia działań zmierzających do nawiązania relacji handlowych, w tym złożenia oferty i zawarcia umowy.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Użytkownicy formularzy kontaktowych</strong></td>
                <td width="70%">Podanie przez Państwa danych niezbędnych do udzielenia i odpowiedzi na zgłoszenie/ pytania, jest warunkiem uzyskania odpowiedzi. Brak podania danych skutkować będzie brakiem możliwości udzielenia odpowiedzi na zgłoszenie/ pytanie.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Osoby kontaktujące się z ADO za pośrednictwem infolinii </strong></td>
                <td width="70%">Podanie przez Państwa danych osobowych jest dobrowolne jednak w określonych sytuacjach może być warunkiem podjęcia przez ADO działań, których celem było nawiązanie połączenia telefonicznego lub przekazanie danych do kontaktu zwrotnego.</td>
              </tr>
              <tr>
                <td width="30%"><strong>Adresaci działań marketingowych</strong></td>
                <td width="70%">Podanie przez Państwa danych jest dobrowolne.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p><strong>KOMU CM ENEL-MED MOŻE PRZEKAZAĆ PAŃSTWA DANE OSOBOWE? </strong></p>
        <p><strong>&nbsp;</strong></p>
        <p>Dostęp do Państwa danych osobowych mogą mieć:</p>
        <ol>
          <li>pracownicy i współpracownicy ADO upoważnieni do przetwarzania Państwa danych osobowych na polecenie ADO;</li>
          <li>w przypadku danych osobowych przetwarzanych w celu świadczenia usług medycznych – inne podmioty lecznicze w celu zapewnienia ciągłości leczenia oraz dostępności świadczeń zdrowotnych;</li>
          <li>podmioty, którym ADO powierzył przetwarzanie danych osobowych, w tym:
            <ul>
              <li>dostawcy usług technicznych i organizacyjnych (w szczególności dostawcy usług teleinformatycznych, dostawcy sprzętu medycznego, podmioty świadczące usługi pocztowe i kurierskie);</li>
              <li>dostawcy usług prawnych i doradczych, w tym w przypadku dochodzenia roszczeń związanych z prowadzoną przez CM ENEL-MED działalnością gospodarczą i obrony przed roszczeniami;</li>
              <li>zleceniodawcy, z którymi ADO zawarł umowy na świadczenie usług medycznych;</li>
            </ul>
          </li>
          <li>inne podmioty, osoby lub organy – w zakresie i na zasadach określonych przepisami prawa, w tym osoby upoważnione przez Państwa w ramach realizacji praw pacjenta;</li>
        </ol>
        <p>z zastrzeżeniem, że udostępnienie Państwa danych ww. podmiotom odbywa się w zgodzie z obowiązującymi przepisami prawa oraz przy zachowaniu pełnych zasad związanych z ich bezpieczeństwem.</p>
        <p>&nbsp;</p>
        <p><strong>CZY PAŃSTWA DANE BĘDĄ PRZEKAZYWANE DO PAŃSTW SPOZA EUROPEJSKIEGO OBSZARU GOSPODARCZEGO (DO&nbsp;KRAJÓW INNYCH NIŻ KRAJE UNII EUROPEJSKIEJ ORAZ ISLANDIA, NORWEGIA I LIECHTENSTEIN)? </strong></p>
        <p><strong>&nbsp;</strong></p>
        <p>ADO może przekazywać Państwa dane osobowe do państw spoza Europejskiego Obszaru Gospodarczego (dalej jako Państwa trzecie) i organizacji międzynarodowych, co jest związane z zakresem prowadzonej przez CM ENEL-MED działalności oraz podejmowanej z różnymi podmiotami współpracy. CM ENEL-MED zapewnia, że dane będą przekazywane do państw, wobec których Komisja Europejska wydała decyzje o spełnianiu przez nie odpowiedniego stopnia ochrony danych osobowych i/lub z zachowaniem wszelkich wymogów prawnych, w tym na podstawie stosownej umowy, zawierającej klauzule ochrony danych przyjęte przez Komisję Europejską lub wiążących reguł korporacyjnych, zapewniając odpowiedni sposób zabezpieczenia przekazywanych danych osobowych.</p>
        <p>&nbsp;</p>
        <p><strong>JAKIE PRAWA PRZYSŁUGUJĄ PAŃSTWU W ZWIĄZKU Z PRZETWARZANIEM DANYCH PRZEZ CM ENEL-MED? </strong></p>
        <p>W związku z przetwarzaniem przez CM ENEL-MED Państwa danych osobowych przysługuje Państwu:</p>
        <ol>
          <li>prawo dostępu do Państwa danych osobowych (art. 15 RODO);</li>
          <li>prawo do sprostowania Państwa danych osobowych (art. 16 RODO);</li>
          <li>prawo do żądania usunięcia danych w przypadkach określonych w art. 17 ust. 1, z uwzględnieniem wyjątków określonych w przepisie art. 17 ust. 3 RODO;</li>
          <li>prawo do żądania ograniczenia przetwarzania danych w przypadkach określonych w art. 18 RODO;</li>
          <li>prawo do przenoszenia danych w przypadkach określonych w przepisach art. 20 RODO.</li>
        </ol>
        <p>Jeżeli chcą Państwo skorzystać z któregokolwiek z tych uprawnień, CM ENEL-MED umożliwia kontakt:</p>
        <ol>
          <li>za pomocą e-maila: <a href="mailto:iod@enel.pl">iod@enel.pl</a>;</li>
          <li>drogą pocztową: ul. Słomińskiego 19 lok. 524, 00-195 Warszawa</li>
          <li>za pomocą formularza kontaktowego dostępnego na stronie www.enel.pl</li>
        </ol>
        <p>Przysługuje Państwu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w przypadku, jeśli przy przetwarzaniu Państwa danych osobowych CM ENEL-MED naruszy przepisy dotyczące ochrony danych osobowych.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>PRAWO DO WYCOFANIA ZGODY NA PRZETWARZANIE DANYCH OSOBOWYCH </strong></p>
        <p>Jeśli ADO przetwarza Państwa dane osobowe na podstawie zgody, przysługuje Państwu prawo wycofania zgody w dowolnym momencie, bez wpływu na ważność przetwarzania, które zostało dokonane na podstawie zgody przed jej wycofaniem.</p>
        <p>Mogą Państwo wycofać zgodę:</p>
        <ol>
          <li>za pomocą e-maila: <a href="mailto:iod@enel.pl">iod@enel.pl</a>;</li>
          <li>w recepcjach oddziałów CM ENEL-MED,</li>
          <li>w systemie on-line,</li>
          <li>drogą pocztową: ul. Słomińskiego 19 lok. 524, 00-195 Warszawa,</li>
          <li>za pomocą formularza kontaktowego dostępnego na stronie <a href="http://www.enel.pl">enel.pl</a>.</li>
          <li>dotyczącą otrzymywania newslettera – postępując według wskazówek w wiadomości e-mail</li>
        </ol>
        <p><strong>CZY WOBEC PANŚTWA DANYCH BĘDĄ PODEJMOWANE ZAUTOMATYZOWANE DECYZJE (DECYZJE BEZ UDZIAŁU CZŁOWIEKA)? </strong></p>
        <p>Wobec Państwa danych osobowych nie będą podejmowane zautomatyzowane decyzje (decyzje bez udziału człowieka), w tym Państwa dane nie będą podlegały zautomatyzowanemu profilowaniu.</p>
        <p>Wyjątkiem od powyższego są decyzje związane z wykonywaniem wiążącej nas z Państwem umowy o udzielanie świadczeń zdrowotnych, np. decyzje dotyczące zapewnienia Państwu odpowiedniej dostępności usług, których podejmowanie w taki sposób jest niezbędne do zawarcia lub wykonania już zawartej umowy.</p>
        <p>&nbsp;</p>
        <p><strong>PRAWO WNIESIENIA SPRZECIWU</strong></p>
        <p>Dodatkowo CM ENEL-MED informuje, że z przyczyn związanych z Państwa szczególną sytuacją przysługuje Państwu prawo do wniesienia sprzeciwu wobec przetwarzania Państwa danych osobowych, w tym profilowania, w przypadku gdy podstawą przetwarzania danych przez ADO jest:</p>
        <ol>
          <li>niezbędność przetwarzania do wykonania zadania realizowanego w interesie publicznym lub w ramach sprawowania władzy publicznej powierzonej administratorowi danych (art. 6 ust. 1 lit. e RODO),</li>
          <li>uzasadniony interes prawny ADO (art. 6 ust. 1 lit. f RODO).</li>
        </ol>
        <p>Po złożeniu sprzeciwu CM ENEL-MED nie będzie mogła przetwarzać danych osobowych opierając się na wyżej wymienionych podstawach przetwarzania, chyba że wykaże istnienie ważnych, prawnie uzasadnionych podstaw do przetwarzania danych, nadrzędnych wobec interesów, praw i wolności osoby, której dane dotyczą, lub podstaw do ustalenia, dochodzenia i obrony roszczeń.</p>
        <p>Jeżeli CM ENEL-MED przetwarza Państwa dane na potrzeby marketingu bezpośredniego, przysługuje Państwu prawo do wniesienia sprzeciwu wobec przetwarzania Państwa danych przez CM ENEL-MED w celach marketingu bezpośredniego, w tym profilowania.</p>
        <p>&nbsp;</p>
        <p><strong>BEZPIECZEŃSTWO DANYCH OSOBOWYCH</strong></p>
        <p>CM ENEL-MED stosuje odpowiednie środki techniczne i organizacyjne by Państwa dane osobowe były bezpieczne, w tym zabezpiecza dane osobowe przed nieuprawnionym dostępem, ich utratą lub zniszczeniem. W celu zapewnienia bezpieczeństwa Państwa danych osobowych w CM ENEL-MED zostały wdrożone odpowiednie zabezpieczenia personalne, organizacyjne, techniczne (informatyczne) i fizyczne.</p>
        <p>&nbsp;</p>
        <p><strong>&nbsp;</strong></p>
        {/*  */}
      </div>
    </section>
  </Layout>
)

export default PolicyPage
